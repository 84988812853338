.approve-reject-dialog {


	.dialog-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-right: 8px;
	}

	.dialog-action {
		padding-right: 24px;
		@media (max-width: 599px) {
			padding-right: 16px;
		}
	}	
}